.footer {
    background-color: #212529;
}

.footer .container {
    display: flex;
    justify-content: space-around;
    align-items: center;    
    min-height: 100px;
    font-size: 10pt;
    color:#808080;
}

.footer .container a {
    padding-right: 3px;
}