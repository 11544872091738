.carousel {
    margin: 0 auto;
    overflow: hidden;
    /* max-width: 900px; */
    position: relative;
    background-color: #212529;    
}

.carousel-inner {
    white-space: nowrap;
    transition: ease 1s;    
}

.carousel-item {
    display: inline-block;
    width: 100%;           
}

.carousel-control {
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    display: inline-block;
    position: absolute;
    height: 120px;
    width: 50px;
    top: calc(50% - 60px);
    color: #f3f3f3;
    cursor: pointer;
    font-size: xx-large;
    font-weight: bold;    
}
.left {
    left: 0;
}
.right {
    right: 0;
}


.carousel-indicators {
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    bottom: 1.5em;
    z-index: 2;    
}

.carousel-indicator-item {
    width: 15px;
    height: 15px;
    border: none;
    background: #fff;
    opacity: 0.5;
    margin: 0.2em;
    border-radius: 50%;
    cursor: pointer;
}

.activeIndicator {
    opacity: 1;
}