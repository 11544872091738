.buttonBadge{
    background-color: #fafafa;
    color: #000;    
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 9px 10px;
    border: 1px solid #dddddd;
    border-radius: 3px;
    display: inline-block;
    width: 100%;    
    text-align:left;    
}

.buttonBadge:hover{
    background-color: #eeeeee;
}

.buttonBadge:after {
    content: attr(data-after-content);   
    float: right;     
    width: 35px;
    height: 21px;
    line-height: 19px;
    text-align: center;
    display: block;
    border-radius: 20px;
    /* background: rgb(67, 151, 232); */
    /* border: 1px solid #FFF; */
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.4); */
    color: #FFF;

    background: #007bff;
    font-size:10pt
}