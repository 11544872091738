@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    /* font-family: 'Poppins', sans-serif; */
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";    
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background: #fafafa;
}

/* img {
    max-width: 100%;
} */

h1,
h2 {
    margin-bottom: 15px;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
}




a.button{
    background-color: #28a745;
    color: #fff;    
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
    text-decoration: none;
    padding: 9px 10px;
    border: 0px solid #000;
    border-radius: 3px;
    display: inline-block;
    min-width: 200px;
}

a.button:hover{
    background-color: #269e41;
}




.cities {
    background-color: #1abc9c;
}

.cities .container {
    display: flex;    
    justify-content: center;
    gap: 30px;
    padding: 3rem 3rem;
}

.cities .container > * { flex: 1; }

.cities .citiesTitle {    
    text-align: center;
    color: #fff;
    font-size: 1.5rem;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: 500;
}

.cities .citiesTitle h1 {
    text-transform: uppercase;
    padding-top: 3rem;
    font-size: 2rem;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: 500;
}


.content .contentTitle h1 {
    text-transform: uppercase;
    padding-top: 3rem;
    font-size: 2rem;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: 500;
}




@media (max-width: 768px) {
    .cities .container {
        flex-direction: column;
        align-items: center;                
        gap: 0px;
    }
}


.content .contentTitle {
    text-align: center;
    margin: 3rem 0 3rem;
}

.center {
    text-align: center;    
}

.content .container {
    display: flex;
    justify-content: center;    
}

.content .container h2 {
    text-transform: uppercase;
    padding-top: 3rem;
    font-size: 1.5rem;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: 500;
}

.contentLogo {
    min-width: 400px; 
    padding: 0 1rem 0 1rem;     
}

.contentBottom {
    text-align: center;
    margin: 3rem 0 3rem;
}

.contentLinks {
    margin: 3rem 0 3rem;
}

@media (max-width: 768px) {
    .content .container {
        flex-wrap: wrap;
    }
}

.fa-check-square {
    color: #9CC925;
    padding-right: 0.5rem;
}

.fa-info-circle {
    color: #9CC925;  
    padding-right: 0.5rem;  
}

.fa-question-circle {
    color: #9CC925;    
    padding-right: 0.5rem;
}











