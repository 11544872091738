.modalBackground {
    width: 100vw;
    height: 100vh;
    transition: background-color 0.5s;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  
  .modalContainer {
    min-width: 450px;
    
    border-radius: 5px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;    
    opacity: 0;
    transition: opacity 0.5s, transform 0.5s; 
  }
  
  
  .modalHeader {
    display: flex;
    border: 1px;
    border-color: #dddddd;
    border-style: none none solid none;
  }
  

  .modalHeader h1 {
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
    padding: 1rem 0 0 1rem;
  }

  .modalHeader button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    color: #505050;
    cursor: pointer;
    padding: 1rem;
  }

  
  .modalContainer .body {
    flex: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    text-align: center;
    padding: 6px 15px 0 15px;
  }
  
  .modalContainer .modalFooter {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .modalFooter button {
    width: 100%;
    height: 40px;
    margin: 10px 0 10px 0;
    border: none;
    background-color: dodgerBlue;
    color: white;
    border-radius: 4px;
    font-size: 20px;
    cursor: pointer;
    font-size: 1rem;
    transition: 0.3s;
  }

  .modalContainer .modalFooter button:hover {
    background-color: rgb(26, 121, 216);  
  }

  .modalContainer .modalFooter button:focus {
    border-color: dodgerBlue;
    box-shadow: 0 0 8px 0 dodgerBlue;
  }  







  input[type="text"], input[type="password"], input[type="email"]  {
    width: 100%;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin: 8px 0;
    outline: none;
    padding: 8px;
    box-sizing: border-box;
    transition: 0.3s;
    font-size: 1rem;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";    
  }
  
  input[type="text"]:focus {
    border-color: dodgerBlue;
    box-shadow: 0 0 8px 0 dodgerBlue;
  }

  input[type="email"]:focus {
    border-color: dodgerBlue;
    box-shadow: 0 0 8px 0 dodgerBlue;
  }

  input[type="password"]:focus {
    border-color: dodgerBlue;
    box-shadow: 0 0 8px 0 dodgerBlue;
  }
  
  .inputWithIcon input[type="text"], input[type="password"], input[type="email"]  {
    padding-left: 35px;
  }
  
  .inputWithIcon {
    position: relative;
    margin: 0 auto;
    width: 100%;
  }
  
  .inputWithIcon i {
    position: absolute;
    left: 0;
    top: 19px;
    padding: 9px 8px;
    color: #aaa;
    transition: 0.3s;
  }
  
  .inputWithIcon input[type="text"]:focus + i {
    color: dodgerBlue;
  }

  .inputWithIcon input[type="email"]:focus + i {
    color: dodgerBlue;
  }

  .inputWithIcon input[type="password"]:focus + i {
    color: dodgerBlue;
  }

  .inputCheckBox {
    text-align: left; 
    padding-top: 10px;
  }




  

  .footerText {
    text-align: center;
    padding: 10px 0 10px 0;  
  }  
  

  .buttonShake {
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    perspective: 100px;
    background-color: red;
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

